// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cashew-nuts-js": () => import("/opt/build/repo/src/pages/cashew-nuts.js" /* webpackChunkName: "component---src-pages-cashew-nuts-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-joc-js": () => import("/opt/build/repo/src/pages/joc.js" /* webpackChunkName: "component---src-pages-joc-js" */),
  "component---src-pages-process-exporting-js": () => import("/opt/build/repo/src/pages/process-exporting.js" /* webpackChunkName: "component---src-pages-process-exporting-js" */),
  "component---src-pages-process-production-js": () => import("/opt/build/repo/src/pages/process-production.js" /* webpackChunkName: "component---src-pages-process-production-js" */),
  "component---src-pages-products-js": () => import("/opt/build/repo/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-tools-js": () => import("/opt/build/repo/src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-tutorial-js": () => import("/opt/build/repo/src/pages/tutorial.js" /* webpackChunkName: "component---src-pages-tutorial-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

